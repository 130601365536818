











































import { vxm } from "@/store";
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  CaseApiFactory,
  CaseDTO,
  CaseStatus,
  Configuration,
} from "@shared_vue/openapi/caseapi";
import Step1 from "@/views/pages/steps/step1.vue";
import { OptionDTO, ProfileDTO } from "@shared_vue/openapi/myvaultapi";
import Step2 from "@/views/pages/steps/step2.vue";

@Component({
  components: { Step1, Step2 },
})
export default class LiveCase extends Vue {
  private ui = vxm.ui;
  private caseStore = vxm.case;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private caseApi = CaseApiFactory(
    <Configuration>{ basePath: process.env.VUE_APP_CASE_API_URL },
    process.env.VUE_APP_CASE_API_URL
  );

  private steps: number = 7;
  private currentStep: number = 1;
  
  public async fetchData(){
    let result = await this.caseApi.caseGetCaseCategoryOptions();
    if (result.status==200){
      let zeroth = <OptionDTO>{ id: "", text: "Choose" }
      this.caseCategory = [zeroth, ...result.data];
    }
  }

  mounted(){
    this.fetchData();
  }
  
  private caseCategory: OptionDTO[] = [
    //this should be fetched from backend. then again maybe cache it? do we want to fetch it EVERY time?
    <OptionDTO>{ id: "", text: "Choose" },
    <OptionDTO>{ id: "1", text: "Accident" },
    <OptionDTO>{ id: "2", text: "Heart Attack" },
    <OptionDTO>{ id: "3", text: "Neuro" },
    <OptionDTO>{ id: "4", text: "Pressure" },
  ];

  private person: ProfileDTO = <ProfileDTO>{};

  private currentCase: CaseDTO = <CaseDTO>{};

  // person2: {
  //   username: 'Helpless noob',
  //   caseType: 'accident',
  //   contact: {
  //     cell: '012313213213',
  //     home: '0123342432',
  //     spouse: '012234324',
  //     work: '0122332434',
  //     email: 'kk@kk.sdsad',
  //   },
  //   date: '2012/01/01',
  //   imicCase: '#12345',
  //   status: 'new',
  //   complaint: 'AAA response panic',
  //   caseOfficer: 'Dr. Zohn Brok',
  //   erpc: 'ERPC NAME',
  //   address: {
  //     home: '28 colombe ave, london',
  //     work: '27 rechard, pinetown, New york'
  //   }
  // }

  private gotToStep(which: number) {
    this.currentStep = which;
  }
}
