












import { vxm } from "@/store";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ProfileDTO } from "@shared_vue/openapi/myvaultapi";
import { CaseDTO } from "@shared_vue/openapi/caseapi";
import { OptionDTO } from "@shared_vue/openapi/myvaultapi";

@Component
export default class Step2 extends Vue {
  @Prop() readonly person: ProfileDTO | undefined;
  @Prop() readonly realCase: CaseDTO | undefined;
  @Prop() readonly caseCategory: OptionDTO[] | undefined;

  private ui = vxm.ui;
  private caseStore = vxm.case;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private selectedCategory: OptionDTO | undefined;
}
